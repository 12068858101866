<template>
  <div class="container>">
    <div class="columns no-margin info">
      <div class="column no-padding">
        <div class="card">
          <header class="card-header has-background-primary">
            <div @click.stop="$router.go(-1)" class="card-header-icon">
              <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
            </div>
            <p class="card-header-title has-text-light is-uppercase">INFORMASI {{ jenisAnggota }}</p>
            <router-link v-if="isEditable" :to="routerTo" class="card-header-icon has-text-light">
              <b-icon class="has-text-light" size="is-small" icon="edit" />
            </router-link>
          </header>
          <div class="card-content events-card">
            <div class="columns">
              <div class="photo-container column is-full">
                <div class="level">
                  <div class="level-item has-text-centered">
                    <img class="img-profil" :src="getWebpPath(anggota.foto)" />
                    <b-field class="file" v-if="canUpload">
                      <b-button loading v-if="isUploading">Loading</b-button>
                      <b-upload v-else v-model="fotoMdl" @input="resize" accept=".jpg, .jpeg, .png">
                        <a>
                          <b-icon type="is-primary" icon="camera" />
                        </a>
                      </b-upload>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">Nama</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ anggota.nama }}</div>
            </div>
            <div v-if="anggota.sub_departemen.length > 0" class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">Admin</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                <ul>
                  <li v-for="subdept in anggota.sub_departemen" :key="subdept">{{ subdept }}</li>
                </ul>
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">Inisial</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ anggota.inisial }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">Alamat</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ anggota.alamat }}</div>
            </div>
            <template v-if="anggota.pspd">
              <div class="columns is-mobile">
                <div class="column detail has-text-weight-semibold is-4">NPM</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left">{{ anggota.pspd.npm }}</div>
              </div>
              <div class="columns is-mobile">
                <div class="column detail has-text-weight-semibold is-4">Angkatan</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left">{{ anggota.pspd.angkatan.nama }}</div>
              </div>
              <div class="columns is-mobile">
                <div class="column detail has-text-weight-semibold is-4">Kelompok</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left">
                  <b-field class="modul-field" grouped group-multiline>
                    <div class="tag-control" v-for="tag in anggota.pspd.tag" :key="tag.id">
                      <b-tag class="is-grey-res">{{ tag.nama }}</b-tag>
                    </div>
                  </b-field>
                </div>
              </div>
              <div class="columns is-mobile">
                <div class="column detail has-text-weight-semibold is-4">Tingkat</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left">{{ anggota.pspd.tingkat}}</div>
              </div>
            </template>
            <template v-else-if="anggota.preceptor">
              <div class="columns is-mobile">
                <div class="column detail has-text-weight-semibold is-4">Departemen</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left">{{ anggota.preceptor.sub_departemen.nama }}</div>
              </div>
              <!-- <div class="columns is-mobile">
                <div class="column detail has-text-weight-semibold is-4">Mulai</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left">{{ anggota.preceptor.mulai }}</div>
              </div>-->
            </template>
            <div class="is-divider" data-content="OR"></div>
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">Email</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ anggota.user }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">No. HP</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ anggota.no_hp}}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">Catatan</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ anggota.catatan}}</div>
            </div>
            <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAktPreceptor" class="columns no-margin">
      <div class="column no-padding">
        <div class="card kelompok">
          <header class="card-header has-background-primary">
            <p class="card-header-title has-text-light">AKTIVITAS PRECEPTOR</p>
          </header>
          <div class="card table-card-content">
            <b-table
              bordered
              striped
              hoverable
              :loading="loading"
              :data="anggota.pencapaian_stase_list"
              :mobile-cards="false"
            >
              <b-table-column
                field="stase"
                label="Stase"
                v-slot="props"
              >{{ props.row.mulai }} s.d {{ props.row.hingga }}</b-table-column>
              <b-table-column field="terverifikasi" label="Terverifikasi" v-slot="props">
                <router-link
                  :to="{ name: 'klinis-list', params: { stase: props.row.id } }"
                >Pengelolaan Kasus : {{ props.row.perawatan_ver }}</router-link>
                <hr />
                <router-link
                  :to="{ name: 'aktivitas-keterampilan-list', params: { stase: props.row.id } }"
                >Keterampilan Klinis : {{ props.row.ket_ver }}</router-link>
                <hr />
                <router-link
                  :to="{ name: 'karyailmiah-list', params: { sub_departemen: props.row.sub_departemen } }"
                >Ilmiah : {{ props.row.ilmiah_ver }}</router-link>
                <hr />
                <router-link
                  :to="{ name: 'ujian-list', params: { sub_departemen: props.row.sub_departemen } }"
                >Ujian : {{ props.row.ujian_ver }}</router-link>
              </b-table-column>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>Belum ada pencapaian stase.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import Anggota from "../models/anggota.js";
import UploadPhoto from "@/apps/core/modules/uploadPhoto.js";
import { webpPath } from "@/apps/core/modules/utils.js";

export default {
  name: "AnggotaDetail",
  mixins: [onlineCheckMixin, fetchCreatedMixin],
  data() {
    this.anggotaMdl = new Anggota();
    this.objectMdl = this.anggotaMdl;
    let obv = this.anggotaMdl.getObservables();
    obv.paramsId = this.$route.params.id;
    obv.fotoMdl = null;
    this.uploadPhoto = new UploadPhoto("anggota", this.notify);
    obv.isUploading = false;
    return obv;
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapGetters("accounts", [
      "isAdmin",
      "isAdminBagian",
      "isAdminPusat",
      "isPreceptor",
    ]),
    isProfile() {
      return this.$route.name === "anggota-me";
    },
    isEditable() {
      return (
        this.isLoaded &&
        (this.isProfile ||
          this.isAdminPusat ||
          (this.isAdminBagian && this.anggota.preceptor) ||
          this.anggota.id == this.userId)
      );
    },
    showAktPreceptor() {
      return (
        this.anggota.preceptor &&
        (this.userId == this.anggota.id || this.isAdmin)
      );
    },
    anggotaId() {
      return this.isProfile ? this.userId : this.paramsId;
    },
    canUpload() {
      return (
        this.isLoaded &&
        (this.isAdminPusat ||
          (this.isAdminBagian && this.anggota.preceptor) ||
          this.anggota.id == this.userId)
      );
    },
    jenisAnggota() {
      let routerName = "anggota";
      if (this.anggota.pspd) {
        routerName = "pspd";
      } else if (this.anggota.preceptor) {
        routerName = "preceptor";
      }
      return routerName;
    },
    routerTo() {
      return {
        name: `${this.jenisAnggota}-change`,
        params: { id: this.anggotaId },
      };
    },
  },
  methods: {
    notify(uploading) {
      this.isUploading = uploading;
    },
    onLoaded() {
      if (!this.canUpload) return;
      this.uploadPhoto.requestAWS();
    },
    fetchData() {
      this.anggotaMdl.load(this.anggotaId, this.onLoaded);
    },
    getWebpPath(url) {
      return webpPath(url);
    },
    resize(foto) {
      this.uploadPhoto.resize(foto, (foto, url) => {
        this.uploadPhoto.upload(
          foto,
          () => this.saveFoto(url),
          null,
          () => {
            this.onLoaded();
          }
        );
      });
    },
    saveFoto(url) {
      let uploadDocUrl = `${APP_CONFIG.baseAPIURL}/anggota/${this.anggota.id}/foto/`;
      let data = { foto: url };
      axios
        .patch(uploadDocUrl, data)
        .then((response) => {
          this.anggota.foto = response.data.foto;
          this.fotoMdl = null;
          this.$buefy.toast.open("Foto berhasil di upload.");
        })
        .catch(() => {
          this.$buefy.toast.open("Foto gagal di upload.");
        });
    },
  },
  watch: {
    "$route.name": function () {
      // diperlukan untuk kasus user buka halaman detail anggota
      // kemudian langsung buka profil.
      this.fetchData();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/detailview.scss";
.info {
  padding-bottom: 1.5rem;
}

.img-profil {
  width: 168px;
  height: 168px;
  object-fit: contain;
}

.level {
  position: relative;
}

// @media screen and (max-width: 480px) {
//   .photo-container {
//     padding: 0.75rem 0;
//   }
// }

.photo-container {
  padding: 0.75rem 0;

  .field {
    position: absolute;
    top: 0;
    right: 0;
  }

  .button {
    border: unset !important;
    padding: 0;
    margin: 0;
  }
}

hr {
  border: none;
  border-top: 1px solid;
  color: #dbdbdb;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>